<!-- 复查建议 -->
<template>
    <div>
        <div style="padding:20px 24px 30px 24px">
            <div class="globalTitle" style="color:#FF830F;margin-bottom:16px">建议您</div>
            <div class="globalText">
                <p style="margin-bottom: 14px;">每6-12个月定期复查免疫功能或调理后复查免疫功能。</p>
                <p style="margin-bottom: 14px;">免疫功能时刻处于动态平衡，因而需要定期监测与及时维护！</p>
                <p>我们将综合您的检测结果为您绘制含不同时间节点的免疫力演变分析图，帮助您直观地看到免疫力变化，方便您选择更适合自己的健康生活方式。</p>
            </div>
            <div v-viewer style="display:flex;justify-content: center; margin-top: 30px;">
                <lazy-img :src="ossUrl + 'evolution.png'" alt="" class="imgTransition" style="width:100%" />
            </div>
            <div class="tip">
                横坐标为每次免疫力检测时间，纵坐标为对应的多样性指数，曲线代表免疫功能演变过程。
            </div>
        </div>
    </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
import lazyImg from '../../../components/lazyImg.vue'
export default {
    components: { lazyImg },
    data() {
        return {
            ossUrl: ossUrl,
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.tip {
    width: 89%;
    height: 34px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 17px;
    margin: auto;
    text-align: center;
    margin-top: 12px;
}
</style>
